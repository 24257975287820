import { createContext, useContext, useState, useEffect } from 'react';
import { logout as authService } from '../services/authService';

const AuthContext = createContext();

const getAccessToken = () => localStorage.getItem('accessToken');
const getRefreshToken = () => localStorage.getItem('refreshToken');
const getUser = () => localStorage.getItem('user');

const isAuthenticated = () => !!getAccessToken();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children, onLogout = () => { } }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());

    useEffect(() => {
        setIsLoggedIn(isAuthenticated());
    }, []);

    const login = (accessToken, refreshToken, user) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('user', JSON.stringify(user));
        setIsLoggedIn(true);
    };

    const logout = async () => {
        const accessToken = getAccessToken();
        try {
            const response = await authService(accessToken);
            if (response.status === 200) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                setIsLoggedIn(false);
                onLogout(); // Call the provided callback function
            } else {
                console.error('Logout was not successful:', response);
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const value = {
        isLoggedIn,
        login,
        logout,
        getAccessToken,
        getRefreshToken,
        getUser,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export { AuthProvider, useAuth, isAuthenticated };