import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { darkTheme, lightTheme } from './index.js';

const LoginPage = lazy(() => import('./components/LoginForm'));
const DashboardPage = lazy(() => import('./components/Admin/Dashboard'));
const PassportList = lazy(() => import('./components/Admin/Passports/PassportList'));
const PassportPublic = lazy(() => import('./components/PassportPublic'));

function withAuthentication(Component) {
  const AuthenticatedComponent = (props) => {
    console.log('AuthenticatedComponent rendered');
    const { isLoggedIn } = useAuth();
    return isLoggedIn ? <Component {...props} /> : <Navigate to="/login" />;
  };

  return React.memo(AuthenticatedComponent);
}

function RedirectToLoginOrDashboard() {
  const { isLoggedIn } = useAuth();
  return <Navigate to={isLoggedIn ? '/dashboard' : '/login'} />;
}

const DashboardWrapper = withAuthentication(DashboardPage);
const PassportsWrapper = withAuthentication(PassportList);

function RoutesWrapper() {
  const location = useLocation();
  const isPublicView = location.pathname.startsWith('/public-view');
  const theme = isPublicView ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route path="/public-view/:id" element={<PassportPublic />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardWrapper />} />
          <Route path="/passports" element={<PassportsWrapper />} />
          <Route path="*" element={<RedirectToLoginOrDashboard />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <RoutesWrapper />
      </Router>
    </AuthProvider>
  );
}

export default App;
