import axios from 'axios';
import { createBrowserHistory } from 'history';

const API_BASE_URL = process.env.REACT_APP_AUTH_API_BASE_URL;
const API_KEY = process.env.REACT_APP_AUTH_API_KEY;

const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
});

const setHeaders = (accessToken, customHeaders) => ({
    'Api-Key': API_KEY,
    'Content-Type': customHeaders['Content-Type'] || 'application/json',
    'Authorization': accessToken ? `Bearer ${accessToken}` : '',
    ...customHeaders,
});

const handleRequest = async (method, url, data, accessToken, customHeaders = {}) => {
    try {
        const requestConfig = {
            method,
            url,
            headers: setHeaders(accessToken, customHeaders),
        };

        if (data !== null) {
            requestConfig.data = data;
        }

        const response = await axiosInstance(requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
};

export const login = async (username, password) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('grant_type', 'password');

    const response = await handleRequest('post', '/token', formData, null, {
        'Content-Type': 'multipart/form-data',
    });

    const accessToken = response.data.data.attributes.accessToken;
    const refreshToken = response.data.data.attributes.refreshToken;
    const user = {
        ...response.data.data.attributes,
        id: response.data.data.id,
    };

    return { accessToken, refreshToken, user };
};

export const logout = async (accessToken) => {
    try {
        const response = await handleRequest('get', '/token/revoke', null, accessToken);

        if (response.status !== 200) {
            console.error('Logout was not successful:', response);
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            console.warn('Token revoke failed with 401 status, clearing tokens and redirecting to login page.');
        } else {
            console.error('Error during token revoke:', error);
        }
    }

    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.removeItem('csvData');
    localStorage.removeItem('csvDataPefc');

    // Redirect to login page
    const history = createBrowserHistory();
    history.push('/login');
    window.location.reload();

    // Return the response object
    return { status: 200 };
};

export const checkTokenValidity = async (accessToken) => {
    const response = await handleRequest('get', '/token/info', null, accessToken);
    return response.data;
};

export const refreshTokenRequest = async (refToken, accToken) => {
    const response = await handleRequest('post', `/token?refresh_token=${refToken}&grant_type=refresh_token`, null, accToken);
    const accessToken = response.data.data.attributes.accessToken;
    const refreshToken = response.data.data.attributes.refreshToken;
    const user = {
        ...response.data.data.attributes,
        id: response.data.data.id,
    };

    return { accessToken, refreshToken, user };
};

export const setAccessAndRefreshToken = (accessToken, refreshToken, user) => {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('user', JSON.stringify(user));
};

export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
};

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
};